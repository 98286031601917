import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useMemo, useState } from "react";

// eslint-disable-next-line react/display-name
const FormikJsonField = React.forwardRef((props, ref) => {
  const { id, formik, clearButton, InputProps, ...MUIProps } = props;
  const [value, setValue] = useState(
    JSON.stringify(formik.values[id], null, 2),
  );
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(formik.touched[id] && Boolean(formik.errors[id]));
  }, [id, formik.touched[id], formik.errors[id]]);

  const Clear = useMemo(() => {
    return (
      <Tooltip title="Limpar" placement="right">
        <InputAdornment position="end">
          <IconButton
            aria-label="Limpar"
            onClick={() => {
              formik.setFieldValue(id, {});
              setValue("{\n}");
              setHasError(false);
            }}
            edge="end"
          >
            <CleaningServicesOutlinedIcon />
          </IconButton>
        </InputAdornment>
      </Tooltip>
    );
  }, [id]);

  function handleChange(e) {
    const value = e.target.value;
    try {
      const newValue = JSON.parse(value);
      formik.setFieldValue(id, newValue);
      setHasError(false);
    } catch (except) {
      console.error(except);
      setHasError(true);
    }
    setValue(value);
  }

  return (
    <TextField
      id={id}
      value={value}
      onChange={handleChange}
      error={hasError}
      multiline
      margin="dense"
      {...MUIProps}
      ref={ref}
      InputProps={{
        onBlur: formik.handleBlur,
        endAdornment: clearButton ? Clear : "",
        ...InputProps,
      }}
    />
  );
});

export default FormikJsonField;
