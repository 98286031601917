export const categories_endpoints = {
  endpoints: (build) => ({
    getCategories: build.query({
      providesTags: ["Categories"],
      query: (params) => ({
        url: "categories/",
        method: "GET",
        params: {
          page: params?.page,
          page_size: params?.pageSize,
          ordering: params?.sort,
          search: params?.search,
          fields: params?.fields,
        },
      }),
    }),
    getCategory: build.query({
      query: (id) => ({
        url: `categories/${id}`,
        method: "GET",
      }),
      providesTags: ["Categories"],
    }),
    createCategory: build.mutation({
      query: (body) => ({
        url: "categories/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategory: build.mutation({
      query: ({ id, body, formData }) => {
        return {
          url: `categories/${id}/`,
          method: "PUT",
          body: body || formData,
          formData: Boolean(formData),
        };
      },
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    deleteCategory: build.mutation({
      query: (id) => ({
        url: `categories/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
  }),
};
