import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useGridApiContext } from "@mui/x-data-grid";

export default function TitleForm({ store }) {
  const apiRef = useGridApiContext();
  const [state, setState] = React.useState({
    name: store.name,
    title: store.title,
    subtitle: store.subtitle,
  });

  const handleValueChange = (event) => {
    (async () => {
      await apiRef.current.setEditCellValue({
        id: store.id,
        field: event.target.name,
        value: event.target.value,
      });
    })();
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  return (
    <Grid container spacing={2} direction="column" sx={{ width: "100%", m: 0 }}>
      <TextField
        onChange={handleValueChange}
        value={state.name}
        fullWidth
        name="name"
        label="Nome"
        variant="standard"
      />
      <TextField
        onChange={handleValueChange}
        value={state.title}
        fullWidth
        name="title"
        label="Título"
        variant="standard"
      />
      <TextField
        onChange={handleValueChange}
        value={state.subtitle}
        fullWidth
        name="subtitle"
        label="Subtítulo"
        variant="standard"
      />
    </Grid>
  );
}
