import FormikAutocomplete from "components/FormikAutocomplete";
import React from "react";

function SelectedRelated({
  name,
  formik,
  excluded = [],
  getter,
  multiple = false,
  ...props
}) {
  let { data } = getter({ fields: ["id", "name"] });

  const options = data
    ? data.filter((obj) => excluded.includes(obj.id) !== true)
    : [];
  let selectedValues;
  if (multiple) {
    selectedValues = data
      ? data.filter((obj) => formik.values[name].includes(obj.id))
      : [];
  } else {
    selectedValues = data
      ? data.filter((obj) => formik.values[name] === obj.id).pop()
      : null;
  }

  return (
    <FormikAutocomplete
      name={name}
      formik={formik}
      options={options}
      value={selectedValues}
      multiple={multiple}
      {...props}
    />
  );
}

export default SelectedRelated;
