import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetNetworkQuery } from "state/api";
import NetworkForm from "../form";

const UpdateNetwork = () => {
  const { id } = useParams();
  let network = useGetNetworkQuery(id);
  if (network.isLoading) {
    return <Loader />;
  }
  return <NetworkForm title="Editar rede" network={network} />;
};

export default UpdateNetwork;
