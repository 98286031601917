import Avatar from "@mui/material/Avatar";
import renderCellExpand from "components/GridCellExpand";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import StarIcon from "@mui/icons-material/Star";
import React from "react";

export const getColumns = () => [
  {
    field: "image",
    headerName: "Imagem",
    cellClassName: "logo--cell",
    renderCell: ({ value, row }) => {
      return (
        <Avatar src={value} alt={row.name} variant="rounded">
          <AppShortcutIcon />
        </Avatar>
      );
    },
    filterable: false,
  },
  {
    field: "name",
    headerName: "Nome",
    renderCell: renderCellExpand,
    flex: 1,
    editable: true,
  },
  {
    field: "slug",
    headerName: "Slug Principal",
    flex: 1,
    editable: true,
  },
  {
    field: "description",
    headerName: "Descrição",
    renderCell: renderCellExpand,
    flex: 1,
    editable: true,
    sortable: false,
  },
  {
    field: "is_popular",
    headerName: <StarIcon />,
    description: "É Popular?",
    type: "boolean",
    editable: true,
    sortable: false,
    flex: 0.3,
  },
  {
    field: "weight",
    headerName: <FitnessCenterIcon />,
    description: "Peso",
    type: "number",
    editable: true,
    sortable: false,
    flex: 0.3,
  },
];
