import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { useMemo } from "react";

// eslint-disable-next-line react/display-name
const FormikTextField = React.forwardRef((props, ref) => {
  const {
    id,
    formik,
    clearButton,
    countChar,
    InputProps,
    helperText,
    ...MUIProps
  } = props;

  const Clear = useMemo(() => {
    return (
      <Tooltip title="Limpar" placement="right">
        <InputAdornment position="end">
          <IconButton
            aria-label="Limpar"
            onClick={() => formik.setFieldValue(id, "")}
            edge="end"
          >
            <CleaningServicesOutlinedIcon />
          </IconButton>
        </InputAdornment>
      </Tooltip>
    );
  }, [id]);

  const hasError = formik.dirty && Boolean(formik.errors[id]);

  const helperComponent = (
    <Grid container justifyContent="space-between" component="span">
      <Grid component="span">{hasError ? formik.errors[id] : helperText}</Grid>
      {countChar && (
        <Grid container sx={{ alignItems: "center" }} component="span">
          <span>{formik.values[id].length}</span>
          <AbcOutlinedIcon />
        </Grid>
      )}
    </Grid>
  );

  return (
    <TextField
      id={id}
      value={formik.values[id]}
      onChange={formik.handleChange}
      error={hasError}
      helperText={helperComponent}
      margin="dense"
      {...MUIProps}
      ref={ref}
      InputProps={{
        onBlur: formik.handleBlur,
        endAdornment: clearButton ? Clear : "",
        ...InputProps,
      }}
    />
  );
});

export default FormikTextField;
