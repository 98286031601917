import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function StoreSlug({ coupon }) {
  return (
    <>
      <Typography
        variant="h6"
        component="a"
        href={coupon.store_url}
        target="_blank"
        color={`coupon.${coupon.kind_class}.main`}
      >
        {coupon.store}
      </Typography>
      <Tooltip title="Slug da loja" placement="left-end">
        <Typography variant="caption">{coupon.store_slug}</Typography>
      </Tooltip>
    </>
  );
}
