import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RestoreIcon from "@mui/icons-material/Restore";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { GridActionsCellItem } from "@mui/x-data-grid";
import ModifiedBy from "components/ModifiedBy";
import { Link as RouterLink } from "react-router-dom";
import { renderActionsCell } from "@mui/x-data-grid";

const columnActions = (
  gridRef,
  unsavedChangesRef,
  setHasUnsavedRows,
  baseUrl,
) => {
  return {
    field: "actions",
    type: "actions",
    headerName: "Ações",
    flex: 0.5,
    renderCell: (params) => {
      return (
        <>
          {renderActionsCell(params)}
          <ModifiedBy {...params.row} newLine={false} fontSize="0.8rem" />
        </>
      );
    },
    getActions: ({ id, row }) => {
      return [
        <Tooltip title="Editar" key="edit">
          <GridActionsCellItem
            component={RouterLink}
            icon={<EditIcon />}
            to={`${baseUrl}/update/${id}`}
            label="Editar"
          />
        </Tooltip>,
        <Tooltip title="Desfazer" key="undo">
          <>
            {" "}
            {/* This is to fix a BUG in MUI Tooltip when the button is disabled */}
            <GridActionsCellItem
              icon={<RestoreIcon />}
              label="Desfazer"
              disabled={unsavedChangesRef.current.unsavedRows[id] === undefined}
              onClick={() => {
                gridRef.current.updateRows([
                  unsavedChangesRef.current.rowsBeforeChange[id],
                ]);
                delete unsavedChangesRef.current.rowsBeforeChange[id];
                delete unsavedChangesRef.current.unsavedRows[id];
                setHasUnsavedRows(
                  Object.keys(unsavedChangesRef.current.unsavedRows).length > 0,
                );
              }}
            />
          </>
        </Tooltip>,
        <GridActionsCellItem
          showInMenu
          component={Link}
          icon={<OpenInNewIcon />}
          label="Abrir página"
          href={row.absolute_url}
          target="_blank"
          key="openPage"
        />,
        <GridActionsCellItem
          showInMenu
          icon={<DeleteIcon />}
          label="Excluir"
          onClick={() => {
            unsavedChangesRef.current.unsavedRows[id] = {
              ...row,
              _action: "delete",
            };
            if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
              unsavedChangesRef.current.rowsBeforeChange[id] = row;
            }
            setHasUnsavedRows(true);
            gridRef.current.updateRows([row]); // to trigger row render
          }}
          key="delete"
        />,
      ];
    },
  };
};

export default columnActions;
