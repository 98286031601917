import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { api_base_url } from "config";

export const Logout = () => {
  useEffect(() => {
    (async () => {
      try {
        const url = api_base_url + "/site/dashboard/logout/";
        await axios.post(
          url,
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
          { withCredentials: true },
        );

        localStorage.clear();
        axios.defaults.headers.common["Authorization"] = null;
        window.location.href = "/login";
      } catch {
        console.log("logout not working");
      }
    })();
  }, []);

  return <div></div>;
};
