import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetStoreQuery } from "state/api";
import StoreForm from "../Storeform";

const UpdateStore = () => {
  const { id } = useParams();
  let store = useGetStoreQuery(id);

  if (store.isLoading) {
    return <Loader />;
  }
  return <StoreForm title="Editar loja" store={store} />;
};

export default UpdateStore;
