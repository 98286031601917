import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetSpecialQuery } from "state/api";
import SpecialForm from "../SpecialForm";

const UpdateSpecial = () => {
  const { id } = useParams();
  let special = useGetSpecialQuery(id);
  if (special.isLoading) {
    return <Loader />;
  }
  return <SpecialForm title="Editar especial" special={special} />;
};

export default UpdateSpecial;
