import React from "react";

import { useGridApiContext } from "@mui/x-data-grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function CodesForm({ coupon }) {
  const apiRef = useGridApiContext();

  const handleValueChange = (event, value) => {
    (async () => {
      await apiRef.current.setEditCellValue({
        id: coupon.id,
        field: "codes",
        value: value.map((v) => {
          if (typeof v === "string") {
            return {
              id: null,
              code: v,
            };
          }
          return v;
        }),
      });
    })();
  };

  return (
    <Autocomplete
      multiple={true}
      value={coupon.codes || []}
      freeSolo={true}
      onChange={handleValueChange}
      options={[]}
      getOptionLabel={(opt) => opt?.code || opt}
      getOptionKey={(opt) => opt?.id || opt}
      renderInput={(p) => <TextField type="filled" {...p} />}
      onKeyDown={(e) => (e.code == "Enter" ? e.stopPropagation() : "")}
    />
  );
}
