import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React, { useCallback } from "react";

export default function FormikAutocomplete({
  name,
  formik,
  multiple = true,
  freeSolo = false,
  ...props
}) {
  const getKey = useCallback(
    (option) => option?.id || option?.name || option,
    [],
  );
  const getLabel = useCallback((option) => option?.name || option, []);

  function renderOption(props, option, { selected }) {
    const { key, ...optionProps } = props;
    return (
      <li key={key} {...optionProps}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {option?.name || option}
      </li>
    );
  }

  const renderOptionMemoized = useCallback(renderOption, []);

  const setRelatedValues = useCallback(
    (_, values) => {
      if (Array.isArray(values)) {
        formik.setFieldValue(
          name,
          values.map((option) => option?.id || option?.name || option),
        );
      } else {
        formik.setFieldValue(name, values?.id || values?.name || values);
      }
    },
    [name],
  );

  return (
    <Autocomplete
      multiple={multiple}
      limitTags={2}
      disableCloseOnSelect
      onChange={setRelatedValues}
      getOptionLabel={getLabel}
      getOptionKey={getKey}
      renderOption={renderOptionMemoized}
      renderInput={(params) => (
        <TextField {...params} type="filled" {...props} />
      )}
      name={name}
      value={formik?.values[name]}
      freeSolo={freeSolo}
      {...props}
    />
  );
}
