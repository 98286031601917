import React from "react";
import {
  useDeleteStoreMutation,
  useGetStoresQuery,
  useUpdateStoreMutation,
  useGetCategoriesQuery,
} from "state/api";
import getColumns from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";
import Loader from "components/Loader";

export const Stores = () => {
  const { data, isLoading } = useGetCategoriesQuery();

  if (isLoading === true) {
    return <Loader />;
  }
  const categories = data.map((cat) => ({ value: cat.id, label: cat.name }));
  return (
    <DataGridEditableRow
      useGet={useGetStoresQuery}
      useUpdate={useUpdateStoreMutation}
      useDelete={useDeleteStoreMutation}
      columns={getColumns(categories)}
      baseUrl="/stores"
      getRowHeight={() => "auto"}
      initialState={{
        columns: {
          columnVisibilityModel: {
            title: false,
            subtitle: false,
            show_at_home: false,
          },
        },
      }}
    />
  );
};

export default React.memo(Stores);
