import CategoryIcon from "@mui/icons-material/Category";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import HomeIcon from "@mui/icons-material/Home";
import PreviewIcon from "@mui/icons-material/Preview";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import TitleForm from "./TitleForm";
import React from "react";

export default function getColumns(categories) {
  return [
    {
      field: "image",
      headerName: "Imagem",
      cellClassName: "logo--cell",
      renderCell: ({ value, row }) => {
        return (
          <Avatar
            sx={{ width: 60, height: 60 }}
            src={value}
            alt={row.name}
            variant="rounded"
          >
            <CategoryIcon />
          </Avatar>
        );
      },
    },

    {
      field: "name",
      colSpan: 1,
      headerName: "Nome",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Nome" placement="left-start">
              <Typography
                variant="h6"
                component="a"
                href={params.row.absolute_url}
                target="_blank"
              >
                {params.row.name}
              </Typography>
            </Tooltip>
            <Tooltip title="SEO Título" placement="left">
              <Typography variant="subtitle2">{params.row.title}</Typography>
            </Tooltip>
            <Tooltip title="SEO Subtítulo" placement="left-end">
              <Typography variant="caption">{params.row.subtitle}</Typography>
            </Tooltip>
          </>
        );
      },
      renderEditCell: (params) => {
        return <TitleForm store={params.row} />;
      },
      flex: 1,
      editable: true,
    },
    {
      field: "title",
      headerName: "",
      editable: true,
      sortable: false,
      headerClassName: "hidden-header-menu",
    },
    {
      field: "subtitle",
      headerName: "",
      editable: true,
      sortable: false,
      headerClassName: "hidden-header-menu",
    },
    {
      field: "slug",
      headerName: "Slug Principal",
      flex: 0.5,
      editable: true,
    },
    {
      field: "category",
      headerName: "Categoria Principal",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: categories,
    },
    {
      field: "network",
      headerName: "Rede",
      flex: 0.5,
      editable: false,
    },
    {
      field: "disabled",
      renderHeader: () => (
        <Tooltip title="Desativada?">
          <VisibilityOffIcon />
        </Tooltip>
      ),
      type: "boolean",
      editable: true,
      sortable: false,
      flex: 0.3,
    },
    {
      field: "show_at_home",
      renderHeader: () => (
        <Tooltip title="Homepage?">
          <HomeIcon />
        </Tooltip>
      ),
      type: "boolean",
      editable: true,
      sortable: false,
      flex: 0.3,
    },
    {
      field: "uncover_coupon",
      renderHeader: () => (
        <Tooltip title="Revelar código?">
          <PreviewIcon />
        </Tooltip>
      ),
      type: "boolean",
      editable: true,
      sortable: false,
      flex: 0.3,
    },
    {
      field: "weight",
      renderHeader: () => (
        <Tooltip title="Peso">
          <FitnessCenterIcon />
        </Tooltip>
      ),
      type: "number",
      editable: true,
      sortable: false,
      flex: 0.3,
    },
  ];
}
