import React from "react";
import TitleDescriptionForm from "./TitleForm";
import FlagsForm from "./FlagsForm";
import StoreSlug from "./StoreSlug";
import TitleDescription from "./TitleDescription";
import CodeList from "./CodeList";
import Flags from "./Flags";
import CodesForm from "./CodesForm";

export default function getColumns(categories, specials, couponKinds) {
  return [
    {
      field: "store",
      headerName: "Loja e Slug",
      flex: 0.7,
      editable: false,
      renderCell: (params) => <StoreSlug coupon={params.row} />,
    },
    {
      field: "title",
      headerName: "Título e Descrição",
      renderCell: (params) => <TitleDescription coupon={params.row} />,
      renderEditCell: (params) => <TitleDescriptionForm coupon={params.row} />,
      flex: 1.7,
      editable: true,
    },
    {
      field: "description",
      editable: true,
      sortable: false,
    },
    {
      field: "codes",
      headerName: "Códigos",
      flex: 0.5,
      editable: true,
      renderCell: (params) => <CodeList coupon={params.row} />,
      renderEditCell: (params) => <CodesForm coupon={params.row} />,
    },
    {
      field: "kind",
      headerName: "Tipo de cupom",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: couponKinds,
    },
    {
      field: "category",
      headerName: "Categoria Principal",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: categories,
    },
    {
      field: "specials",
      headerName: "Especiais",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: specials,
    },

    {
      field: "exclusive",
      headerName: "Flags",
      flex: 0.4,
      editable: true,
      renderCell: (params) => <Flags coupon={params.row} />,
      renderEditCell: (params) => {
        return <FlagsForm coupon={params.row} />;
      },
    },
    {
      field: "disabled",
      type: "boolean",
      editable: true,
      sortable: false,
    },
    {
      field: "show_at_home",
      type: "boolean",
      editable: true,
      sortable: false,
    },
    {
      field: "track_url",
      type: "boolean",
      editable: true,
      sortable: false,
    },
  ];
}
