import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PreviewIcon from "@mui/icons-material/Preview";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import SaveIcon from "@mui/icons-material/Save";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Loader from "components/Loader";
import ModifiedBy from "components/ModifiedBy";
import { useFormik } from "formik";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateStoreMutation,
  useDeleteStoreMutation,
  useUpdateStoreMutation,
} from "state/api";
import * as Yup from "yup";
import SeoForm from "components/SeoForm";
import SocialForm from "components/SocialForm";
import BaseConfigurationForm from "./BaseConfigurationForm";
import formDataFromJson from "utils/formDataFromJson";

const SEOSchema = {
  title: Yup.string(),
  subtitle: Yup.string(),
  description: Yup.string(),
  after_coupons: Yup.string(),
  before_coupons: Yup.string(),
  meta_description: Yup.string().max(
    160,
    "Este campo tem um tamanho máximo de ${max} caracteres",
  ),
  og_title: Yup.string(),
  og_description: Yup.string().max(
    160,
    "Este campo tem um tamanho máximo de ${max} caracteres",
  ),
  image: Yup.mixed().nullable(),
  og_image: Yup.mixed().nullable(),
  indexable: Yup.boolean().required(),
  followable: Yup.boolean().required(),
};
const SEODefaultValues = {
  title: "",
  subtitle: "",
  description: "",
  after_coupons: "",
  before_coupons: "",
  meta_description: "",
  og_title: "",
  og_description: "",
  image: null,
  og_image: null,
  indexable: true,
  followable: true,
};

const storeSchema = Yup.object({
  id: Yup.string(),

  ...SEOSchema,

  name: Yup.string().required("O nome da categoria é obrigatório."),
  slug: Yup.string().required("O slug é obrigatório."),
  disabled: Yup.boolean(),
  show_at_home: Yup.boolean(),
  uncover_coupon: Yup.boolean(),
  general_rules: Yup.string().nullable(),
  weight: Yup.number()
    .positive("Apenas números positivos")
    .integer("Apenas números inteiros")
    .required("É obrigatório."),
  coupon_code_name: Yup.string(),

  alternative_slugs: Yup.array().of(
    Yup.object({ id: Yup.string().optional(), name: Yup.string() }),
  ),
  network: Yup.string(),
  category: Yup.string(),
  categories: Yup.array().of(Yup.string()),
  specials: Yup.array().of(Yup.string()).ensure(),
  related_stores: Yup.array().of(Yup.string()),
  similar_stores: Yup.array().of(Yup.string()),

  tracking_params: Yup.object({
    key: Yup.string(),
    value: Yup.string(),
  }).optional(),
  source_url: Yup.string().required(),
  tracked_url: Yup.string().required(),
  authorized_domains: Yup.string(),

  seller_code_name: Yup.string(),
  seller_code: Yup.string(),
  seller_code_redeem_suggest: Yup.boolean(),
  coupon_redeem_suggest: Yup.boolean(),
});

const defaultValue = {
  id: "",
  ...SEODefaultValues,

  name: "",
  slug: "",
  disabled: false,
  show_at_home: false,
  uncover_coupon: false,
  general_rules: "",
  weight: 1,
  coupon_code_name: "Cupom de Desconto",

  alternative_slugs: [],
  network: null,
  category: null,
  categories: [],
  specials: [],
  related_stores: [],
  similar_stores: [],

  tracking_params: {},
  tracked_url: "https://",
  source_url: "https://",
  authorized_domains: "",

  seller_code_name: "Código de vendedor",
  seller_code: "",
  seller_code_redeem_suggest: false,
  coupon_redeem_suggest: false,
};

const StoreForm = ({ title, store = false }) => {
  const { id } = useParams();
  const form = useRef();
  const [activeTab, setActiveTab] = useState("1");
  const [formSnack, setFormSnack] = useState();
  const [confirmDeletionIsOpen, setConfirmDeletionIsOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [createStore] = useCreateStoreMutation();
  const [updateStore] = useUpdateStoreMutation();
  const [deleteStore] = useDeleteStoreMutation();

  const formik = useFormik({
    initialValues: store ? store.data : defaultValue,
    onSubmit: async (values) => {
      try {
        const formData = formDataFromJson(storeSchema.cast(values));
        let resp;
        if (id) {
          resp = await updateStore({ id, formData });
        } else {
          resp = await createStore(formData);
        }
        if (!resp.error) {
          setFormSnack({
            msg: "Formulário salvo com sucesso",
            severity: "success",
          });
          navigate("/stores");
        } else {
          const errors = Object.entries(resp.error.data).reduce(
            (prev, current) => prev + `${current[0]} ${current[1]}\n\n`,
            "",
          );
          setFormSnack({ msg: errors, severity: "error" });
        }
      } catch (e) {
        setFormSnack({ msg: "Erro ao salvar formulário", severity: "error" });
        console.error(e);
      }
    },
    validationSchema: storeSchema,
    validateOnMount: true,
  });

  const handleTabChange = useCallback((event, newTab) => {
    setActiveTab(newTab);
  }, []);

  const handleFormSnackClose = useCallback(() => setFormSnack(undefined), []);

  const handleDelete = useCallback(() => {
    try {
      setConfirmDeletionIsOpen(false);
      setIsLoading(true);
      deleteStore(id);
      setIsLoading(false);
      setFormSnack({ msg: "Loja removida com sucesso", severity: "success" });
      setTimeout(() => {
        setFormSnack(false);
        navigate("/categories");
      }, 2000);
    } catch (error) {
      setFormSnack({ msg: "Erro ao excluir loja", severity: "error" });
      console.error(error);
    }
  }, [id]);

  const FormActions = useCallback(() => {
    return (
      <>
        <Tooltip title={formik.isValid ? "Salvar" : "Fomulário com erro"}>
          <span>
            <IconButton
              disabled={!formik.isValid}
              form="catForm"
              type="submit"
              size="medium"
              color="secondary"
            >
              <SaveIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Clonar">
          <span>
            <IconButton disabled={!store} size="medium" color="secondary">
              <ContentCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Visitar">
          <span>
            <IconButton
              href={store?.data?.absolute_url}
              disabled={!store}
              target="_blank"
              size="medium"
              color="secondary"
            >
              <PreviewIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Excluir">
          <span>
            <IconButton
              onClick={() => setConfirmDeletionIsOpen(true)}
              disabled={!store}
              size="medium"
              color="secondary"
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  }, [store, formik.isValid]);

  if ((store?.data === undefined || isLoading) && store !== false) {
    return <Loader />;
  }

  return (
    <>
      <Card elevation={2} sx={{ width: "90%" }}>
        <CardHeader
          title={title}
          subheader={<ModifiedBy {...store?.data} />}
          action={<FormActions />}
          subheaderTypographyProps={{ variant: "caption" }}
        />
        <Divider />
        <CardContent>
          <form
            ref={form}
            id="catForm"
            onSubmit={formik.handleSubmit}
            encType="multipart/form-data"
          >
            <TabContext value={activeTab}>
              <TabList onChange={handleTabChange} centered>
                <Tab label="Básicas" value="1" />
                <Tab label="SEO" value="2" />
                <Tab label="Social" value="3" />
              </TabList>
              <Divider variant="middle" />
              <TabPanel value="1">
                <BaseConfigurationForm store={store?.data} formik={formik} />
              </TabPanel>
              <TabPanel value="2">
                <SeoForm formik={formik} />
              </TabPanel>
              <TabPanel value="3">
                <SocialForm object={store?.data} formik={formik} />
              </TabPanel>
            </TabContext>
          </form>
        </CardContent>
      </Card>
      <Snackbar
        open={Boolean(formSnack)}
        autoHideDuration={5000}
        onClose={handleFormSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={formSnack?.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {formSnack?.msg}
        </Alert>
      </Snackbar>
      <Dialog open={Boolean(confirmDeletionIsOpen)}>
        <DialogTitle>
          <ReportProblemOutlinedIcon
            fontSize="large"
            color="warning"
            sx={{ verticalAlign: "bottom" }}
          />
          {"Tem certeza que deseja excluir essa loja?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} color="warning">
            Sim
          </Button>
          <Button
            onClick={() => setConfirmDeletionIsOpen(false)}
            variant="outlined"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StoreForm;
