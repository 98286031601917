import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import FormikAvatar from "components/FormikAvatar";
import FormikTextField from "components/FormikTextField";
import React from "react";

export default function SocialForm({ object, formik }) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} md={3}>
          <FormikAvatar
            name="og_image"
            formik={formik}
            src={object?.og_image}
            label="Imagem redes sociais"
          />
        </Grid>
        <Grid xs={12} md={9}>
          <FormikTextField
            id="og_title"
            name="og_title"
            label="Título para rede social"
            fullWidth
            color="secondary"
            formik={formik}
          />
          <FormikTextField
            id="og_description"
            name="og_description"
            label="Texto para redes sociais"
            fullWidth
            multiline
            color="secondary"
            minRows={5}
            maxRows={5}
            formik={formik}
            clearButton
            countChar
          />
        </Grid>
      </Grid>
    </>
  );
}
