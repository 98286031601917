import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import AutoFixOffOutlinedIcon from "@mui/icons-material/AutoFixOffOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AlternativeSlugs from "components/AlternativeSlugs";
import FormikAvatar from "components/FormikAvatar";
import FormikTextField from "components/FormikTextField";
import SelectedRelated from "components/SelectRelated";
import React, { useCallback, useEffect, useState } from "react";
import slugify from "react-slugify";
import { useGetCategoriesQuery, useGetSpecialsQuery } from "state/api";

export default function BaseConfigurationForm({ category, formik }) {
  const [autoSlug, setAutoSlug] = useState(true);

  const AutoSlugButton = useCallback(() => {
    return (
      <Tooltip title={autoSlug ? "Automático" : "Manual"}>
        <InputAdornment position="end">
          <IconButton
            aria-label={autoSlug ? "Slug automático" : "Slug manual"}
            onClick={() => setAutoSlug((state) => !state)}
            edge="end"
          >
            {autoSlug ? (
              <AutoFixHighOutlinedIcon color="primary" />
            ) : (
              <AutoFixOffOutlinedIcon />
            )}
          </IconButton>
        </InputAdornment>
      </Tooltip>
    );
  }, [autoSlug]);

  useEffect(() => {
    if (autoSlug) {
      formik.setFieldValue("slug", slugify(formik.values.name));
    }
  }, [autoSlug, formik.values.name]);

  return (
    <Grid container spacing={2} rowSpacing={1}>
      <Grid direction="column" xs={12} md={3}>
        <Grid xs={6} sm={1} textAlign="center">
          <Tooltip title="É popular?">
            <Checkbox
              checked={formik.values.is_popular}
              id="is_popular"
              name="is_popular"
              aria-label="É popular?"
              onChange={formik.handleChange}
              icon={<GradeOutlinedIcon fontSize="large" />}
              checkedIcon={<GradeIcon color="primary" fontSize="large" />}
            />
          </Tooltip>
        </Grid>
        <Grid textAlign="center">
          <FormikAvatar
            name="image"
            formik={formik}
            src={category?.image}
            label="Imagem principal"
          />
        </Grid>
      </Grid>
      <Grid container direction="column" xs={12} md={9}>
        <Grid>
          <FormikTextField
            fullWidth
            id="name"
            name="name"
            label="Nome"
            formik={formik}
          />
        </Grid>
        <Grid>
          <FormikTextField
            fullWidth
            id="slug"
            name="slug"
            label="Slug"
            formik={formik}
            InputProps={{
              endAdornment: <AutoSlugButton />,
            }}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={12} md={4}>
            <Tooltip title="Prioridade da categoria">
              <FormikTextField
                id="weight"
                name="weight"
                label="Peso"
                formik={formik}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid xs={12} md={4}>
            <Tooltip title="Quantidade máxima de cupons na página">
              <FormikTextField
                id="max_coupon_on_page"
                name="max_coupon_on_page"
                label="Na página"
                formik={formik}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid xs={12} md={4}>
            <Tooltip title="Quantidade máxima de cupons por loja">
              <FormikTextField
                id="num_max_coupons_store"
                name="num_max_coupons_store"
                label="Por loja"
                formik={formik}
                fullWidth
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <FormikTextField
          id="description"
          name="description"
          label="Descrição para o usuário"
          fullWidth
          multiline
          color="secondary"
          minRows={3}
          maxRows={3}
          formik={formik}
          clearButton
          countChar
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="related_categories"
          name="related_categories"
          label="Categorias relacionadas"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetCategoriesQuery}
          excluded={[category?.id]}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="related_specials"
          name="related_specials"
          label="Especiais relacionadas"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetSpecialsQuery}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <AlternativeSlugs
          name="alternative_slugs"
          label="Slugs alternativos"
          placeholder="Criar"
          formik={formik}
        />
      </Grid>
    </Grid>
  );
}
