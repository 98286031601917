import * as React from "react";
import Backdrop from "@mui/material/Backdrop";

export default function Loader() {
  return (
    <div>
      <Backdrop
        sx={{
          backgroundColor: "snoopy.orange.translucent",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={true}
      >
        <img src="/snoopy-bored.gif" />
      </Backdrop>
    </div>
  );
}
