import renderCellExpand from "components/GridCellExpand";

export default function getColumns(trackingMethods) {
  return [
    {
      field: "name",
      headerName: "Nome",
      flex: 0.5,
      renderCell: renderCellExpand,
      editable: true,
    },
    {
      field: "tracking_method",
      headerName: "Tracking Método",
      flex: 0.5,
      editable: true,
      type: "singleSelect",
      valueOptions: trackingMethods,
    },
    {
      field: "template",
      headerName: "Template",
      flex: 1,
      editable: true,
    },
    {
      field: "params",
      headerName: "Parâmetros",
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: (value) => {
        return JSON.stringify(value);
      },
    },
  ];
}
