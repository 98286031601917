import React from "react";

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PowerIcon from "@mui/icons-material/Power";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

export default function Flags({ coupon }) {
  return (
    <>
      <Stack direction="row">
        <Tooltip title="Exclusivo" placement="left-start">
          <AutoAwesomeIcon color={coupon.exclusive ? "primary" : "error"} />
        </Tooltip>
        <Tooltip title="Capa" placement="right-end">
          <Typography variant="caption">
            <HomeIcon color={coupon.show_at_home ? "primary" : "error"} />
          </Typography>
        </Tooltip>
      </Stack>
      <Stack direction="row">
        <Tooltip title="Ativado" placement="left-end">
          <Typography variant="caption">
            <PowerIcon color={coupon.disabled ? "error" : "primary"} />
          </Typography>
        </Tooltip>
        <Tooltip title="Rastrear URL" placement="right-end">
          <Typography variant="caption">
            <FollowTheSignsIcon
              color={coupon.track_url ? "primary" : "error"}
            />
          </Typography>
        </Tooltip>
      </Stack>
    </>
  );
}
