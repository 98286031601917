import FindInPageIcon from "@mui/icons-material/FindInPage";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import LocalTaxiOutlinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import FormikTextField from "components/FormikTextField";
import React from "react";
import FormikTinyMCE from "components/FormikTinyMCE";

export default function SeoForm({ formik }) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid xs={12} md={1}>
          <Grid container direction="column">
            <Grid>
              <Tooltip title="É indexável?">
                <Checkbox
                  checked={formik.values.indexable}
                  id="indexable"
                  name="indexable"
                  aria-label="É indexável?"
                  onChange={formik.handleChange}
                  icon={<FindInPageOutlinedIcon fontSize="large" />}
                  checkedIcon={
                    <FindInPageIcon color="secondary" fontSize="large" />
                  }
                />
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip title="Segue links?">
                <Checkbox
                  checked={formik.values.followable}
                  id="followable"
                  name="followable"
                  aria-label="Segue links?"
                  onChange={formik.handleChange}
                  icon={<LocalTaxiOutlinedIcon fontSize="large" />}
                  checkedIcon={
                    <LocalTaxiIcon color="secondary" fontSize="large" />
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={11}>
          <Grid>
            <FormikTextField
              id="title"
              name="title"
              label="Título (Meta título)"
              fullWidth
              color="secondary"
              formik={formik}
              countChar
            />
          </Grid>
          <Grid>
            <FormikTextField
              id="subtitle"
              name="subtitle"
              label="Subtítulo (Meta)"
              fullWidth
              color="secondary"
              formik={formik}
              countChar
            />
          </Grid>
          <Grid>
            <FormikTextField
              id="meta_description"
              name="meta_description"
              label="Descrição para mecanismos de busca"
              fullWidth
              multiline
              color="secondary"
              minRows={2}
              maxRows={2}
              formik={formik}
              clearButton
              countChar
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <FormikTinyMCE
            id="before_coupons"
            label="Texto antes da lista de cupom"
            formik={formik}
          />
        </Grid>
        <Grid xs={12}>
          <FormikTinyMCE
            id="after_coupons"
            label="Texto após a lista de cupons"
            formik={formik}
          />
        </Grid>
      </Grid>
    </>
  );
}
