import Tooltip from "@mui/material/Tooltip";
import React from "react";

const options = {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export default function ModifiedBy({
  last_modified,
  last_modified_by,
  newLine = true,
  fontSize = "",
}) {
  const date = new Date(last_modified);

  return (
    <>
      <Tooltip title="Autor" placement="right" arrow>
        <span style={{ fontSize }}>{last_modified_by || "Sistema"}</span>
      </Tooltip>
      {newLine && <br />}
      <Tooltip title="Última atualização" placement="right" arrow>
        <span style={{ fontSize }}>
          {date.toLocaleDateString("pt-BR", options)}
        </span>
      </Tooltip>
    </>
  );
}
