import { orange, yellow } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

// mui theme settings
export const themeSettings = () => {
  return {
    typography: {
      fontFamily: ["sans-serif", "Roboto", "Raleway"],
    },
    palette: {
      snoopy: {
        orange: {
          main: orange[50],
          translucent: alpha(orange[50], 0.5),
        },
        yellow: {
          main: yellow[900],
        },
      },
      coupon: {
        yellow: {
          main: "#ffd105",
        },
        blue: {
          main: "#0982c4",
        },
        grey: {
          main: "#999999",
        },
        orange: {
          main: "#f89924",
        },
        black: {
          main: "#000",
        },
        green: {
          main: "#8cc31d",
        },
      },
    },
  };
};
