import React from "react";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function TitleDescription({ coupon }) {
  return (
    <>
      <Tooltip title="Título" placement="left-start">
        <Typography variant="h6" color={`coupon.${coupon.kind_class}.main`}>
          {coupon.title}
        </Typography>
      </Tooltip>
      <Tooltip title="Descrição" placement="left-end">
        <Typography variant="caption">{coupon.description}</Typography>
      </Tooltip>
    </>
  );
}
