import React from "react";

import { useGridApiContext } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";

export default function FlagsForm({ coupon }) {
  const apiRef = useGridApiContext();
  const [state, setState] = React.useState({
    exclusive: coupon.exclusive,
    show_at_home: coupon.show_at_home,
    disabled: coupon.disabled,
    track_url: coupon.track_url,
  });

  const handleValueChange = (event) => {
    (async () => {
      await apiRef.current.setEditCellValue({
        id: coupon.id,
        field: event.target.name,
        value: event.target.checked,
      });
    })();
    setState((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <>
      <Stack direction="row">
        <Tooltip title="Exclusivo" placement="left-start">
          <Checkbox
            name="exclusive"
            checked={state.exclusive}
            onChange={handleValueChange}
            icon={<AutoAwesomeIcon color="error" />}
            checkedIcon={<AutoAwesomeIcon color="primary" />}
          />
        </Tooltip>
        <Tooltip title="Capa" placement="right-end">
          <Checkbox
            name="show_at_home"
            checked={state.show_at_home}
            onChange={handleValueChange}
            icon={<HomeIcon color="error" />}
            checkedIcon={<HomeIcon color="primary" />}
          />
        </Tooltip>
      </Stack>
      <Stack direction="row">
        <Tooltip title="Ativado" placement="left-end">
          <Checkbox
            name="disabled"
            checked={state.disabled}
            onChange={handleValueChange}
            icon={<PowerIcon color="primary" />}
            checkedIcon={<PowerOffIcon color="error" />}
          />
        </Tooltip>
        <Tooltip title="Rastrear URL" placement="right-end">
          <Checkbox
            name="track_url"
            checked={state.track_url}
            onChange={handleValueChange}
            icon={<FollowTheSignsIcon color="error" />}
            checkedIcon={<FollowTheSignsIcon color="primary" />}
          />
        </Tooltip>
      </Stack>
    </>
  );
}
