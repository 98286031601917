export const coupon_kind_endpoints = {
  endpoints: (build) => ({
    getCouponKinds: build.query({
      providesTags: ["Coupon-kind"],
      query: (params) => ({
        url: "coupon-kind/",
        method: "GET",
        params: {
          page: params?.page,
          page_size: params?.pageSize,
          ordering: params?.sort,
          search: params?.search,
          fields: params?.fields,
        },
      }),
    }),
    getCouponKind: build.query({
      query: (id) => ({
        url: `coupon-kind/${id}`,
        method: "GET",
      }),
      providesTags: ["Coupon-kind"],
    }),
    createCouponKind: build.mutation({
      query: (body) => ({
        url: "coupon-kind/",
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["Coupon-kind"],
    }),
    updateCouponKind: build.mutation({
      query: ({ id, body, formData }) => {
        return {
          url: `coupon-kind/${id}/`,
          method: "PUT",
          body: body || formData,
          formData: Boolean(formData),
        };
      },
      invalidatesTags: ["Coupon-kind"],
    }),
    deleteCouponKind: build.mutation({
      query: (id) => ({
        url: `coupon-kind/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Coupon-kind"],
    }),
  }),
};
