import React from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

export default function CodeList({ coupon }) {
  if (Boolean(coupon.codes) === false) return "";

  let coupon_codes = [...coupon.codes];

  let codes = coupon_codes
    .sort((a, b) => a.code.length - b.code.length)
    .slice(0, 3)
    .map((code) => {
      return (
        <Chip
          label={code.code}
          key={code.code}
          variant="outlined"
          size="small"
          sx={{
            borderColor: `coupon.${coupon.kind_class}.main`,
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
            },
          }}
        />
      );
    });

  let all_codes = "";
  if (coupon.codes.length > 4) {
    all_codes = (
      <Tooltip
        title={
          <React.Fragment>
            {coupon.codes.map((code) => (
              <Box key={code.code}>
                {code.code}
                <br />
              </Box>
            ))}
          </React.Fragment>
        }
      >
        <Chip label="..." variant="outlined" size="small" />
      </Tooltip>
    );
  }

  return (
    <Stack direction="column" spacing={1} sx={{ my: 1 }}>
      {codes}
      {all_codes}
    </Stack>
  );
}
