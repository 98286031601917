export default function (data) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value === undefined || value === null) continue;

    if (["image", "og_image"].includes(key) && typeof value === "string")
      continue;

    if (Array.isArray(value)) {
      if (value.length === 0) continue;

      for (const innerValue of value) {
        if (typeof innerValue === "object") {
          formData.append(key, JSON.stringify(innerValue));
        } else {
          formData.append(key, innerValue);
        }
      }
    } else if (typeof value === "object") {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}
