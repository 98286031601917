import React from "react";
import PropTypes from "prop-types";
import FormikAutocomplete from "components/FormikAutocomplete";
import slugify from "react-slugify";
import { Formik } from "formik";

export default function AlternativeSlugs({ name, formik, ...props }) {
  const handleChange = (event, newValues, actionType) => {
    if (actionType === undefined) return;

    if (actionType === "createOption") {
      const newOption = newValues.pop();
      formik.setFieldValue(name, [
        ...formik.values[name],
        { id: String(Math.random()), name: slugify(newOption) },
      ]);
    } else {
      formik.setFieldValue(name, newValues || []);
    }
  };

  return (
    <FormikAutocomplete
      name={name}
      formik={formik}
      onChange={handleChange}
      freeSolo={true}
      options={[]}
      {...props}
    />
  );
}

AlternativeSlugs.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.instanceOf(Formik).isRequired,
};
