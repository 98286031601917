import Loader from "components/Loader";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetCategoryQuery } from "state/api";
import CategoryForm from "../CategoryForm";

const UpdateCategory = () => {
  const { id } = useParams();
  let category = useGetCategoryQuery(id);
  if (category.isLoading) {
    return <Loader />;
  }
  return <CategoryForm title="Editar categoria" category={category} />;
};

export default UpdateCategory;
