import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import Navbar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { drawerWidth } from "config";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const Main = styled(
  "main",
  {},
)(({ isMobile }) => {
  let attrs = {
    minHeight: "calc(100vh - 64px)",
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
  }; // 64px from theme.mixins.toolbar.minHeight
  if (!isMobile) {
    return {
      ...attrs,
      width: `calc(100% - ${drawerWidth}px)`,
    };
  }
  return attrs;
});

const Layout = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  return (
    <Box sx={{ display: "flex", paddingTop: "64px" }}>
      {/** 64px from theme.mixins.toolbar.minHeight */}
      <Navbar
        user={{}}
        isMobile={isMobile}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        drawerWidth={drawerWidth}
        isMobile={isMobile}
      />
      <Main isMobile={isMobile}>
        <Outlet />
      </Main>
    </Box>
  );
};

export default Layout;
