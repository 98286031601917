import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import AutoFixOffOutlinedIcon from "@mui/icons-material/AutoFixOffOutlined";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import AlternativeSlugs from "components/AlternativeSlugs";
import FormikAvatar from "components/FormikAvatar";
import FormikTextField from "components/FormikTextField";
import React, { useCallback, useEffect, useState } from "react";
import slugify from "react-slugify";
import {
  useGetCategoriesQuery,
  useGetNetworksQuery,
  useGetSpecialsQuery,
  useGetStoresQuery,
} from "state/api";
import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BlindsIcon from "@mui/icons-material/Blinds";
import BlindsClosedIcon from "@mui/icons-material/BlindsClosed";
import PowerIcon from "@mui/icons-material/Power";
import PowerOffIcon from "@mui/icons-material/PowerOff";
import Divider from "@mui/material/Divider";
import FormikJsonField from "components/FormikJsonField";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import SelectedRelated from "components/SelectRelated";
import PreviewIcon from "@mui/icons-material/Preview";

export default function BaseConfigurationForm({ category, formik }) {
  const [autoSlug, setAutoSlug] = useState(true);

  const AutoSlugButton = useCallback(() => {
    return (
      <Tooltip title={autoSlug ? "Automático" : "Manual"}>
        <InputAdornment position="end">
          <IconButton
            aria-label={autoSlug ? "Slug automático" : "Slug manual"}
            onClick={() => setAutoSlug((state) => !state)}
            edge="end"
          >
            {autoSlug ? (
              <AutoFixHighOutlinedIcon color="primary" />
            ) : (
              <AutoFixOffOutlinedIcon />
            )}
          </IconButton>
        </InputAdornment>
      </Tooltip>
    );
  }, [autoSlug]);

  useEffect(() => {
    if (autoSlug) {
      formik.setFieldValue("slug", slugify(formik.values.name));
    }
  }, [autoSlug, formik.values.name]);

  return (
    <Grid container spacing={2} rowSpacing={1}>
      <Grid direction="column" xs={12} md={3}>
        <Grid xs={6} sm={1} textAlign="center">
          <Tooltip title="Loja desativada?">
            <Checkbox
              checked={formik.values.disabled}
              id="disabled"
              name="disabled"
              aria-label="Desativada?"
              onChange={formik.handleChange}
              icon={<PowerIcon fontSize="large" color="primary" />}
              checkedIcon={<PowerOffIcon color="error" fontSize="large" />}
            />
          </Tooltip>
          <Tooltip title="Exibir na Home?">
            <Checkbox
              checked={formik.values.show_at_home}
              id="show_at_home"
              name="show_at_home"
              aria-label="Exibir na home?"
              onChange={formik.handleChange}
              icon={<HomeOutlinedIcon fontSize="large" />}
              checkedIcon={<HomeIcon color="primary" fontSize="large" />}
            />
          </Tooltip>
          <Tooltip title="Revelar códigos?">
            <Checkbox
              checked={formik.values.uncover_coupon}
              id="uncover_coupon"
              name="uncover_coupon"
              aria-label="Revelar códigos?"
              onChange={formik.handleChange}
              icon={<BlindsClosedIcon fontSize="large" />}
              checkedIcon={<BlindsIcon color="primary" fontSize="large" />}
            />
          </Tooltip>
        </Grid>
        <Grid textAlign="center">
          <FormikAvatar
            name="image"
            formik={formik}
            src={category?.image}
            label="Imagem principal"
          />
        </Grid>
      </Grid>
      <Grid container direction="column" xs={12} md={9}>
        <Grid>
          <FormikTextField
            fullWidth
            id="name"
            name="name"
            label="Nome"
            formik={formik}
          />
        </Grid>
        <Grid>
          <FormikTextField
            fullWidth
            id="slug"
            name="slug"
            label="Slug principal"
            formik={formik}
            InputProps={{
              endAdornment: <AutoSlugButton />,
            }}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid xs={12} md={2}>
            <Tooltip title="Prioridade da categoria">
              <FormikTextField
                id="weight"
                name="weight"
                label="Peso"
                formik={formik}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid xs={12} md={4}>
            <Tooltip title="Título do botão que esconde o código.">
              <FormikTextField
                id="coupon_code_name"
                name="coupon_code_name"
                label="Título botão"
                formik={formik}
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Grid xs={12} sm={6}>
            <AlternativeSlugs
              name="alternative_slugs"
              label="Slugs alternativos"
              placeholder="Criar"
              formik={formik}
              sx={{ mt: "4px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <FormikTextField
          id="general_rules"
          name="general_rules"
          label="Regras gerais"
          fullWidth
          multiline
          color="secondary"
          minRows={1}
          maxRows={2}
          formik={formik}
          clearButton
          countChar
        />
      </Grid>

      <Divider flexItem sx={{ width: "100%", pt: 4 }}>
        Relacionamentos
      </Divider>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="network"
          name="network"
          label="Rede de afiliado"
          placeholder="Pesquisar"
          formik={formik}
          multiple={false}
          getter={useGetNetworksQuery}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="category"
          name="category"
          label="Categoria principal"
          placeholder="Pesquisar"
          formik={formik}
          multiple={false}
          getter={useGetCategoriesQuery}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="categories"
          name="categories"
          label="Categorias adicionais"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetCategoriesQuery}
          multiple={true}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="specials"
          name="specials"
          label="Especiais"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetSpecialsQuery}
          multiple={true}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="related_stores"
          name="related_stores"
          label="Lojas relacionadas"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetStoresQuery}
          multiple={true}
        />
      </Grid>
      <Grid xs={12} sm={4}>
        <SelectedRelated
          id="similar_stores"
          name="similar_stores"
          label="Lojas similares"
          placeholder="Pesquisar"
          formik={formik}
          getter={useGetStoresQuery}
          multiple={true}
        />
      </Grid>

      <Divider flexItem sx={{ width: "100%", pt: 4 }}>
        Trackeamento
      </Divider>
      <Grid xs={12} md={6}>
        <FormikTextField
          id="source_url"
          name="source_url"
          label="URL Original"
          formik={formik}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Visitar URL original">
                  <IconButton
                    aria-label="Visitar URL original"
                    edge="end"
                    href={formik.values["source_url"]}
                    target="_blank"
                    disabled={
                      typeof formik.values["source_url"] !== "string" ||
                      formik.values["source_url"].length === 0
                    }
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <FormikTextField
          id="tracked_url"
          name="tracked_url"
          label="URL Trackeada"
          formik={formik}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Visitar URL trackeada">
                  <IconButton
                    aria-label="Visitar URL original"
                    edge="end"
                    href={formik.values["tracked_url"]}
                    target="_blank"
                    disabled={
                      typeof formik.values["tracked_url"] !== "string" ||
                      formik.values["tracked_url"].length === 0
                    }
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={12} textAlign="center">
        <FormikJsonField
          fullWidth
          id="tracking_params"
          name="tracking_params"
          label="Parâmetros"
          formik={formik}
          clearButton
        />
      </Grid>
      <Grid xs={12}>
        <FormikTextField
          id="authorized_domains"
          name="authorized_domains"
          label="Domínios autorizados"
          formik={formik}
          fullWidth
        />
      </Grid>

      <Divider flexItem sx={{ width: "100%", pt: 4 }}>
        Código de vendedor
      </Divider>
      <Grid xs={1}>
        <Tooltip title="Sugerir código de vendedor no resgate">
          <Checkbox
            checked={formik.values.seller_code_redeem_suggest}
            id="seller_code_redeem_suggest"
            name="seller_code_redeem_suggest"
            aria-label="Sugerir código de vendedor no resgate"
            onChange={formik.handleChange}
            icon={<HandshakeOutlinedIcon fontSize="large" />}
            checkedIcon={<HandshakeIcon color="primary" fontSize="large" />}
          />
        </Tooltip>
      </Grid>
      <Grid xs={11} md={6}>
        <FormikTextField
          id="seller_code_name"
          name="Seller_code_name"
          label="Nomenclatura do código de vendedor"
          formik={formik}
          fullWidth
        />
      </Grid>
      <Grid xs={12} md={5}>
        <FormikTextField
          id="seller_code"
          name="Seller_code"
          label="Código de vendedor"
          formik={formik}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}
